import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  isProduction = false;

  constructor() {
    this.isProduction = environment.production;
  }

  addGAScripts(googleAnalyticsCode, oldGoogleAnalyticsCode) {
    if (this.isProduction && environment.env === 'prd') {
      this.addGAScript(environment.googleAnalyticsGeral);
      this.addGAScript(googleAnalyticsCode);
      this.addGAScript(oldGoogleAnalyticsCode);
    }
    if (!environment.env.includes('local')) {
      this.addGAScript(environment.oldGoogleAnalyticsGeral);
    }
  }

  addGAScript(googleAnalyticsCode) {
    if (googleAnalyticsCode) {
      const gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsCode;
      document.head.prepend(gtagScript);
      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', googleAnalyticsCode, { send_page_view: false });
    }
  }

  addPagePathToAnalytics(path) {
    if (this.isProduction && environment.env === 'prd') {
      gtag('event', 'page_view', {
        page_path: path
      });
    }
  }

}
