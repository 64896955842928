import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { enumTranslateRolesInRoutes } from '../enums/enumTranslateRolesInRoutes';
import { IntegrationCsmRequest } from '../models/integration-csm-request.model';
import { LoginTokenResponse } from '../models/login-token-response.models';
import { LoginTokenSiaResponse } from '../models/login-token-sia-response.module';
import { User } from '../models/user';
import { UserDetails } from '../models/user-details';
import { CredentialService } from './shared/credential.service';
import { LoaderService } from './shared/loader.service';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: User;
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public initLogin: Subject<boolean> = new Subject<boolean>();

  private userLoggedIn = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _permissionsService: NgxPermissionsService,
    private _startupService: StartupService,
    private _credentialService: CredentialService,
    private _loaderService: LoaderService,
  ) {
    this.userLoggedIn.next(false);
   }

  login(userName: string, userPassword: string, type: string): Observable<User> {
    const data = {
      userName,
      userPassword,
      portal: this._startupService.getPortalCode(),
      type
    };
    return this._http.post(`${environment.authApiUrl}/auth/login`, data).pipe(
      map((response: any) => {

        if (response.accessToken) {
          this.updateCurrentToken(response.accessToken);

          this.addUserRoles();
          this.setUserLoggedIn(true);
          return this.decodeToken(response.accessToken);
        } else {
          throw new Error(response.message);
        }
      })
    );
  }

  loginAsCompany(email, password) {
    return this.login(email, password, 'company').pipe(first());
  }

  signinWithCsm(token: string): Observable<any> {
    const data: IntegrationCsmRequest = {
      credential: token,
      portal: this._startupService.getPortalCode()
    };

    this.initLogin.next(true);
    const signinSymplicityUrl = `${environment.authApiUrl}/csm-auth/login/signin-symplicity`

    return this._http.post(signinSymplicityUrl, data).pipe(
      map((response: LoginTokenResponse) => {

        if (response.accessToken) {
          this.updateCurrentToken(response.accessToken);

          this.addUserRoles();
          this.initLogin.next(false);
          this.setUserLoggedIn(true);
          return this.decodeToken(response.accessToken);
        } else {
          this.initLogin.next(false);
          throw new Error(response.message);
        }
      })
    );

  }
  getUserDetails(): Observable<UserDetails> {
    return this._http.get<UserDetails>(`${environment.apiUrl}/user`).pipe(
      map(data => {
        const userDetails = data;
        userDetails.shortName = this.getUser().short_name.toUpperCase();
        return userDetails;
      })
    );
  }

  // TODO: definir tipo para retorno dessa função
  signinWithSia(token: string): Observable<any> {

    const data = {
      token,
      role: "estudante"
    };

    this.initLogin.next(true);
    return this._http.post(`${environment.authApiUrl}/sia-auth/login/sia`, data).pipe(
      map((response: LoginTokenSiaResponse) => {
        if (response.accessToken) {
          this.updateCurrentToken(response.accessToken);

          this.addUserRoles();
          this.initLogin.next(false);
          this.setUserLoggedIn(true);
          return {
            decodedToken: this.decodeToken(response.accessToken),
            action: response.enumLoginTokenAction
          };
        } else {
          this.initLogin.next(false);
          throw new Error(response.message);
        }
      })
    );
  }

  signinWithCredential(credential: string): Observable<any> {
    const data = {
      credential,
      portal: this._startupService.getPortalCode()
    };
    this.initLogin.next(true);

    return this._http.post(`${environment.authApiUrl}/auth/login/credential`, data).pipe(
      map((response: LoginTokenResponse) => {
        if (response.accessToken) {
          this.updateCurrentToken(response.accessToken);
          this.addUserRoles();
          this.initLogin.next(false);
          this.setUserLoggedIn(true);
          return this.decodeToken(response.accessToken);
        } else {
          this.initLogin.next(false);
          throw new Error(response.message);
        }
      })
    );
  }


  setRole(role) {
    const data = {
      token: this.getCurrentToken(),
      role
    };

    return this._http.post(`${environment.authApiUrl}/auth/login/token`, data).pipe(
      map((response: any) => {
        if (response.accessToken) {
          this.updateCurrentToken(response.accessToken);
          this.addUserRoles();

          return this.decodeToken(response.accessToken);
        } else {
          throw new Error(response.message);
        }
      })
    );
  }

  logout(): void {
    this.logoutAngular();
    this.logoutLegacy();
  }

  logoutLegacy() {

    // create iframe element
    const logoutIframe = document.createElement('iframe');
    logoutIframe.setAttribute('id', 'logoutIframe');
    logoutIframe.style.position = 'absolute';
    logoutIframe.style.display = 'none';
    document.body.appendChild(logoutIframe);

    // setup for read load event
    logoutIframe.addEventListener('load', () => {
      logoutIframe?.contentWindow?.postMessage('logout', '*');
      window.localStorage.setItem('loggedOut', 'true');
      setTimeout(() => {
        logoutIframe.remove();
      }, 1000);
    });

    // add a real fast src to this iframe so it minimize the delay to send load event
    const legacyUrl = this._startupService.getLegacyUrl();
    const protocol = `${legacyUrl?.includes('http') ? '' : `${environment.httpSchema}://`}`;
    const logoutUrl = `${protocol}${legacyUrl}/html/function-iframe.html`;
    // logoutIframe.contentWindow.location.replace(logoutUrl);
    logoutIframe.setAttribute('src', logoutUrl);

  }

  logoutAngular() {
    this.deleteCurrentToken();
    this.removeUserPermissions();
  }

  logoutAndRedirectToLogin(queryParam?: string): void {
    this._loaderService.show();
    this.RedirectToLogin(queryParam);
    this.logout();
  }

  RedirectToLogin(queryParam?: string) {
    setTimeout(() => {
      if (queryParam) {
        this._router.navigate([`auth`], { queryParams: { returnUrl: queryParam } });
      } else {
        this._router.navigate([`auth`]);
      }

      this._loaderService.forceHide();
    }, 500);
  }


  isLoggedIn(): boolean {
    return this.getCurrentToken() != null && !this.isTokenExpired() && !!this.getUserRole();
  }
  private setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }


  public isTokenExpired() {
    const token = this.getCurrentToken();
    const isExpired = this.jwtHelper.isTokenExpired(token);
    return isExpired;
  }

  addUserRoles() {
    const currentUser = this.getUser();
    const roles: any[] = [];
    if (!(currentUser.roles instanceof Array)) {
      roles.push(currentUser.roles);
      this._permissionsService.loadPermissions(roles);
    }
    this.addUserPermissions();
  }

  addUserPermissions() {
    const currentUser = this.getUser();
    this._permissionsService.addPermission(currentUser.permissions);
  }

  reloadUser() {
    // this.currentUserToken = localStorage.getItem('userToken');
    this.addUserRoles();
  }

  removeUserPermissions() {
    this._permissionsService.flushPermissions();
  }

  getUser(): User {
    return this.decodeToken(this.getCurrentToken());
  }

  decodeToken(token: string) {
    return this.jwtHelper.decodeToken(token);
  }

  getUserCredential(): string {
    return this.getUser()?.credential;
  }

  getUserRole(): string {
    return this.getUser()?.user_role;
  }
  getUserRoleTranslated(): string {
    return enumTranslateRolesInRoutes[this.getUser()?.user_role];
  }
  getUserCode(): string {
    return this.getUser()?.user_code;
  }
  getCompanyCode(): string {
    return this.getUser()?.company_code;
  }

  private updateCurrentToken(accessToken) {
    if (localStorage.getItem('userToken')) {
      localStorage.removeItem('userToken');
    }
    localStorage.setItem('newUserToken', accessToken);
    this._credentialService.updateCredential(this.getUserCredential());
  }

  public getCurrentToken() {
    if (localStorage.getItem('userToken')) {
      localStorage.removeItem('userToken');
    }
    return localStorage.getItem('newUserToken');
  }

  private deleteCurrentToken() {
    if (localStorage.getItem('userToken')) {
      localStorage.removeItem('userToken');
    }
    localStorage.removeItem('newUserToken');
  }

}
