<div class="container-fluid" style="border-bottom: 0;">
  <div class="d-flex justify-content-between align-items-center" [class.flex-wrap]="isMobile">
    <div class="header d-flex align-items-center ">
      <ng-container *ngIf="backButtonTpl; else elseTemplate">
        <ng-template *ngTemplateOutlet="backButtonTpl"></ng-template>
      </ng-container>
      <ng-template #elseTemplate>
        <a cnet-icon-button *ngIf="returnUrl" class="mr-2 mb-1" [routerLink]="[returnUrl]">
          <cnet-icon name="angle-left"></cnet-icon>
        </a>
      </ng-template>

      <ng-container *ngIf="isHeaderTitleString(); else headerTpl">
        <h2 class="title" [ngStyle]="{'margin-left': !returnUrl && !backButtonTpl ? '3rem' : '0' }">{{headerTitle}}</h2>
      </ng-container>
      <ng-template #headerTpl>
        <h2 class="title" [ngStyle]="{'margin-left': !returnUrl && !backButtonTpl ? '3rem' : '0' }">
          <ng-template *ngTemplateOutlet="headerTitle"></ng-template>
        </h2>
      </ng-template>


    </div>
    <div class="content" [class.mb-2]="isMobile" [class.margin-left-3]="isMobile">
      <ng-content></ng-content>
    </div>
  </div>
</div>
