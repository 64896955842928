import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CnetNgxUiModule } from '@cnet/ngx-ui';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { MyMissingTranslationHandler, TranslateHttpLoaderFactory } from '../core/i18n/i18n.module';
import { PageContentModule } from './components/page-content/page-content.module';
import { PageContetSideNavModule } from './components/page-contet-side-nav/page-contet-side-nav.module';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { IframeRenderedDirective } from './pages/iframe-error/iframe-rendered.directive';
import { FrequencyPipeModule } from './pipes/frequency-pipe/frequency-pipe.module';
import { InternshipTypePipeModule } from './pipes/internship-type-translate-pipe/internship-type-translate-pipe.module';
import { LocalizeDateModule } from './pipes/localize-date/localize-date.module';
import { StatusToBadgesModule } from './pipes/status/status-to-badges/status-to-badges.module';
import { StatusTypePipeModule } from './pipes/status/status-type-translate-pipe/status-type-translate.module';
import { TargetTypePipeModule } from './pipes/target-type-trasnlate-pipe/target-type-translate-pipe.module';
import { DecimalPipe } from './pipes/decimal.pipe';
import { DecimalPipeModule } from './pipes/decimal-pipe/decimal-pipe.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SectionDetailDirective } from './components/wiki/section/directives/section-detail.directive';
import { IframeHandlerComponent } from './components/iframe-handler/iframe-handler.component';
import { BackgroundImageDirective } from './components/info-card-components/info-card/directive/background-image.directive';
import { FiscalCodePipe } from './pipes/fiscal-code-pipe/fiscal-code.pipe';

@NgModule({
  declarations: [SubHeaderComponent, IframeRenderedDirective, DecimalPipe, SectionDetailDirective],
  imports: [
    CommonModule,
    CnetNgxUiModule,
    RouterModule,
    LocalizeDateModule,
    InternshipTypePipeModule,
    TargetTypePipeModule,
    StatusTypePipeModule,
    StatusToBadgesModule,
    FrequencyPipeModule,
    PageContetSideNavModule,
    DecimalPipeModule,
    IframeHandlerComponent,
    BackgroundImageDirective,
    FiscalCodePipe,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    })
  ],
  exports: [
    CnetNgxUiModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule,
    SubHeaderComponent,
    RouterModule,
    TranslateModule,
    IframeRenderedDirective,
    LocalizeDateModule,
    PageContentModule,
    InternshipTypePipeModule,
    TargetTypePipeModule,
    StatusTypePipeModule,
    StatusToBadgesModule,
    FrequencyPipeModule,
    PageContetSideNavModule,
    DecimalPipeModule,
    IframeHandlerComponent,
    BackgroundImageDirective,
    FiscalCodePipe,
    NgxMaskDirective, NgxMaskPipe
  ],
  providers: [provideNgxMask()]
})
export class SharedModule { }
