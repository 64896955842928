import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CnetSignatureEvaluationDto } from 'src/app/core/models/cnet-signature-evaluation';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { EvaluationService } from '../evaluation/evaluation.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureService {

  constructor(private http: HttpClient, private evaluationService: EvaluationService, private router: Router,
    private authService: AuthService,
  ) { }

  getEvaluationDocumentByEvaluationCode(evaluationCode) {
    return this.evaluationService.getEvaluationDocumentByEvaluationCode(evaluationCode)
  }

  signatureEvaluation(signature: CnetSignatureEvaluationDto) {
    return this.http.post<any>(`${environment.apiUrl}/evaluation/signature/digital`, signature);
  }

  existsCertificate() {
    return this.http.get<any>(`${environment.apiUrl}/digital-signature/certificate/exist`)
  }

  navigateToResetPassword(openChangePasswordNewTab?) {
    if (openChangePasswordNewTab) {
      return this.router.navigate([]).then((result) => { window.open(`/l/${this.authService.getUserRoleTranslated()}/digital-password`, '_blank') });
    }

    return this.router.navigate([`/l/${this.authService.getUserRoleTranslated()}/digital-password`], { queryParams: { 'returnUrl': window.location.pathname } })
  }

}
