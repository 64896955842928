import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { DigitalSignatureService } from '../digital-signature.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureGuard  {

  constructor(
    private digitalSignatureService: DigitalSignatureService,
    private router: Router,
    private authService: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.digitalSignatureService.existsCertificate().pipe(
      map(response => true),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.digitalSignatureService.navigateToResetPassword();
        } else if (error.status === HttpStatusCode.Unauthorized) {
          this.router.navigate(['/unauthorized'])
        }
        return [];
      }),
    )
  }

}
