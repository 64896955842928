<div class="page-contant-container container" [class.mt-4]="hasMargin" [class.hasNoMarginBottom]="!hasMargin"
  [class.no-border]="!hasBorder">

  <cnet-card class="card-page-content" [class.no-padding]="!hasPadding" [class.no-background]="!hasBackground">

    <header cnet-card-header *ngIf="title || titleTemplate || isLoading">

      <h2 cnet-card-title [class.loading]="isLoading" id="page-title">
        <ng-container *ngIf="(!titleTemplate || isLoading); else elseHasTitleTemplate">
          {{title}}
        </ng-container>
        <ng-template #elseHasTitleTemplate>
          <ng-template *ngTemplateOutlet="titleTemplate"></ng-template>
        </ng-template>
      </h2>


      <ng-container cnet-card-subtitle *ngIf="subTitle || subTitleTemplate">
        <p *ngIf="!subTitleTemplate else elseHasSubTitleTemplate" [class.loading]="isLoading" id="page-sub-title">
          {{subTitle}}
        </p>
        <ng-template #elseHasSubTitleTemplate>
          <ng-template *ngTemplateOutlet="subTitleTemplate"></ng-template>
        </ng-template>
      </ng-container>

      <div cnet-card-actions *ngIf="actionsTemplate">
        <ng-template *ngTemplateOutlet="actionsTemplate"></ng-template>
      </div>
    </header>

    <div class="m-3 card-content" [class.loading]="isLoading">
      <ng-content></ng-content>
    </div>
    <footer cnet-card-footer *ngIf="footerTemplate" id="page-footer">
      <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
    </footer>
  </cnet-card>
</div>
